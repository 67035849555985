<template>
    <div>
        <header-portrate />
        <div style="margin-bottom:70px;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
                <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:25px !important;`">
                    <v-col
                        cols="12" md="12" sm="12" class="text-end">
                        <b-button variant="success"  
                        class="btn-sm addemployeecl" 
                        :style="`width:145px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat @click="resetTitle()" 
                        v-b-toggle.add_employee 
                        id="multiply-button"
                        color="success">
                        <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_employee}}</div>
                        </b-button>
                    </v-col>
                </v-row>
            <div class="mainrow m-2">
                <v-simple-table dense fixed-header>
                    <thead>
                        <th class="text-center">{{ lang.full_name }}</th>
                        <th class="text-center">{{ lang.mobile }}</th>
                        <th class="text-center">{{ lang.basic_salary }}</th>
                        <th class="text-center">{{ lang.home_allownance }}</th>
                        <th class="text-center">{{ lang.trans_allownance }}</th>
                        <th class="text-center">{{ lang.food_allown }}</th>
                        <th class="text-center">{{ lang.other_allown }}</th>
                        <th class="text-center">{{ lang.percentage }}</th>
                        <th class="text-center">{{ lang.total }}</th>
                        <th class="text-center">{{ lang.action }}</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in employees" :key="index">
                            <td class="text-center">{{item.employee_name}}</td>
                            <td class="text-center">{{item.mobile}}</td>
                            <td class="text-center">{{item.salary}}</td>
                            <td class="text-center">{{item.home_allown}}</td>
                            <td class="text-center">{{item.tarns_allown}}</td>
                            <td class="text-center">{{item.food_allown}}</td>
                            <td class="text-center">{{item.others_allown}}</td>
                            <td class="text-center">{{item.percentage}}</td>
                            <td class="text-center">{{item.stotal}}</td>
                            <td class="text-center" style="width:60px;background:#000">
                                <v-btn style="width:100px;font-size:0.2em;background:#000;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="ViewItem(item)" v-b-toggle.add_employee>{{lang.update}}</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
        <addEmployee ref="addeditemp" />
        <Footer />
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import addEmployee from '@/components/addEmployee.vue'
import axios from 'axios'
export default {
    components: {addEmployee,BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            employees: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return '';
        },
        tabs: function()
        {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
        resetTitle()
        {
            this.$refs.addeditemp.user.startdate = this.$refs.addeditemp.date1;
            this.$refs.addeditemp.toptitle = 'اضافة موظف';
            this.$refs.addeditemp.resetValues();
        },
        ViewItem(item){
            this.$refs.addeditemp.user = item;
            this.$refs.addeditemp.toptitle = 'تعديل موظف';
        },
        getEmployees() {
            const post = new FormData();
            post.append("type" , "getAllEmp");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log("asdfas",response.data);
                this.employees = response.data.results.data;
            })
        }
    },
    created() {
        this.getEmployees();
    }
}
</script>